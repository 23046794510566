import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Accordion from "../components/Accordion"

const Faq = () => {
  const { t } = useTranslation();

  const faqList = [
    {
      id: 1,
      question: t("faq1"),
      answer: t("answer1"),
    },
    {
      id: 2,
      question: t("faq2"),
      answer: t("answer2"),
    },
    {
      id: 3,
      question: t("faq3"),
      answer: t("answer3"),
    },
    {
      id: 4,
      question: t("faq4"),
      answer: t("answer4"),
    },
    {
      id: 5,
      question: t("faq5"),
      answer: t("answer5"),
    },
    {
      id: 6,
      question: t("faq6"),
      answer: t("answer6"),
    }
  ]

  const mainEntity = faqList.map(item => {
   return {
      "@type": "Question",
      "name": item.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer
      }
    }
  })

  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": mainEntity
  }

  const seo = {
    title: t("metaFaqTitle"),
    description: t("metaFaqDescription"),
    keywords: t("metaFaqKeywords"),
    schema: schema
  }

  return (
    <Layout className="bg-reallyDarkBlue">
      <Seo {...seo} />
      <div className="bg-reallyDarkBlue relative py-10">
        <h1 className="text-4xl md:text-6xl w-max font-bold mx-auto text-center text-white">{t("faqTitle")}</h1>
        <div className="my-16 border-y-[0.5px] border-blue divide-y-[0.5px] divide-blue">
          {faqList.map(item => (
            <Accordion
              key={item.id}
              answer={item.answer}
              question={item.question}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Faq

export const query = graphql`
   query ($language: String!){
    locales: allLocale(filter: { language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

