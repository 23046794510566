import React, { useState } from "react"

const Accordion = ({ question, answer }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <div>
        <button
          className="w-full transition hover:bg-darkBlue"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <div className="lg:max-w-2xl xl:max-w-5xl mx-auto">
            <div className="w-full md:w-3/4 lg:w-full px-8 sm:px-0 md:mx-auto flex justify-between py-6">
              <h2 className="w-5/6 md:w-full text-left font-bold text-white">{question}</h2>
              <div className={`inline-flex items-center justify-center h-6 w-6 bg-reallyDarkBlue rounded-full transition duration-[250ms] ${dropdownOpen ? "rotate-180" : ""}`}>
                <svg
                  width="8"
                  height="10"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current text-white"
                >
                  <path
                    d="M6.8 1.20002C6.8 0.758197 6.44183 0.400024 6 0.400024C5.55817 0.400024 5.2 0.758197 5.2 1.20002L6.8 1.20002ZM5.43432 12.9657C5.74673 13.2781 6.25327 13.2781 6.56569 12.9657L11.6569 7.87454C11.9693 7.56212 11.9693 7.05559 11.6569 6.74317C11.3444 6.43075 10.8379 6.43075 10.5255 6.74317L6 11.2687L1.47452 6.74317C1.1621 6.43075 0.655565 6.43075 0.343146 6.74317C0.0307266 7.05559 0.0307266 7.56212 0.343146 7.87454L5.43432 12.9657ZM5.2 1.20002L5.2 12.4L6.8 12.4L6.8 1.20002L5.2 1.20002Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </button>

        <div className="lg:max-w-2xl xl:max-w-5xl mx-auto">
          <div className={`${dropdownOpen ? 'max-h-[96rem]' : 'max-h-0'} w-full md:w-3/4 lg:w-full px-8 sm:px-0 md:mx-auto overflow-hidden transition-all duration-[250ms]`}>
            <p className="pt-4 pb-12 whitespace-pre-line text-left pr-6 text-white ">{answer}</p>
          </div>
        </div>
    </div>
  )
}

export default Accordion

